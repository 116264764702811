.fc-event{
   border: none;
   border-radius: 7px;
   border-left: 4px solid;
   padding: 0 10px;
   font-size: 16px;
   color: black !important;
}

.fc{
    background-color: white;
    padding: 20px;
    border-radius: 20px;
}

.fc-day-sat, .fc-day-sun{
    background-color: rgba(255, 255, 255) !important;
} 

.fc-theme-standard th, .fc .fc-timegrid-slot,  .fc-theme-standard td, .fc-theme-standard .fc-scrollgrid{
    border-color: #F0F0F0;
}